<template>
  <v-card outlined class="mt-6">
    <v-card-title class="text-subtitle-1 px-3 py-2 lightgray">
      {{ $tc("global.add") }} {{ $tc("global.formapagamento") }}
      <v-spacer></v-spacer>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="py-7">
      <v-row>
        <!-- Forma de Pagamento -->
        <v-col cols="12">
          <v-combobox
            class="ma-0"
            height="40px"
            :items="formaPagamentos"
            item-text="descricao"
            item-value="id"
            :loading="loadingFormaPagamentos"
            :label="$tc('global.formapagamento')"
            placeholder="Selecione uma forma de pagamento"
            clearable
            dense
            outlined
            hide-details
            v-model="formaPagamentoSelected"
          ></v-combobox>
        </v-col>

        <v-col
          v-if="
            formaPagamentoSelected &&
            (formaPagamentoSelected.id === 6 || formaPagamentoSelected.id === 7)
          "
        >
          <v-combobox
            class="ma-0"
            height="40px"
            :items="bandeiras"
            item-text="bandeira"
            item-value="id"
            :loading="loading"
            :label="$tc('global.bandeira')"
            clearable
            dense
            outlined
            hide-details
            v-model="selectedBandeira"
          ></v-combobox>
          <v-text-field
            v-model="codigoAutorizacao"
            :label="$tc('global.codigoAutorizacao')"
            class="pt-4"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>

        <BaixaCheque
          v-if="formaPagamentoSelected && formaPagamentoSelected.id === 4"
          :valor_restante.sync="valorRestante"
          :moeda_venda="moeda_conta"
          :allowCheque.sync="allowCheque"
          :cheque.sync="chequeAdd"
          :cheques_numeros="cheques_criados"
        />

        <BaixaAntecipo
          v-if="formaPagamentoSelected && formaPagamentoSelected.id === 8"
          :valor_restante.sync="valorRestante"
          :moeda_venda="moeda_conta"
          :antecipo.sync="antecipo"
          :cliente_id="cliente_id"
          :antecipos_adicionados="antecipos_adicionados"
          @push-antecipo="pushAntecipo"
        />

        <template v-if="formaPagamentoSelected">
          <template
            v-if="
              formaPagamentoSelected.id === 1 ||
              formaPagamentoSelected.id === 2 ||
              formaPagamentoSelected.id === 6 ||
              formaPagamentoSelected.id === 7
            "
          >
            <v-col cols="12" md="6">
              <v-text-field
                v-if="formaPagamentoSelected.id === 2"
                v-model="formaPagamentoItem.documento"
                :label="$tc('global.documento')"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-combobox
                v-if="formaPagamentoSelected.id === 2"
                v-model="caixaSelected"
                :items="caixas"
                item-text="descricao"
                item-value="id"
                :loading="loading"
                :label="$tc('global.caixa')"
                clearable
                dense
                outlined
                hide-details
              ></v-combobox>
            </v-col>
            <!-- Valor -->
            <v-col cols="12" md="12" class="d-flex">
              <div class="d-flex">
                <v-btn icon small @click="enableSelectMoeda" class="mr-2 mt-2">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-select
                  v-model="selectedMoeda"
                  :items="moedas"
                  item-text="sigla"
                  item-value="id"
                  label="Moedas"
                  dense
                  class="mr-2"
                  :disabled="blockSelectedMoeda"
                  clearable
                  outlined
                  hide-details
                  return-object
                ></v-select>
              </div>

              <v-text-field
                v-model="formaPagamentoValor"
                label="Valor"
                ref="formaPagamentoValor"
                outlined
                dense
                class="input-right"
                v-currency="vCurrencyOptions"
                :disabled="disableValor"
                @blur="validatePagamentoValor"
              ></v-text-field>

              <v-text-field
                v-model="formaPagamentoCambio"
                label="Câmbio"
                ref="formaPagamentoCambio"
                outlined
                dense
                class="input-right ml-2"
                @blur="atualizarConsolidado"
                :disabled="disableCambio"
                v-currency="
                  moeda_conta == 'G$' ? vCurrencyOptions2 : vCurrencyOptions
                "
              ></v-text-field>

              <div>
                <v-text-field
                  v-model="formaPagamentoConsolidado"
                  label="Consolidado"
                  ref="formaPagamentoConsolidado"
                  outlined
                  dense
                  class="input-right ml-2"
                  :disabled="disableCambio"
                  @blur="atualizarCambio"
                  v-currency="vCurrencyOptions2"
                  :error="formaPagamentoValorInputError"
                  :error-messages="formaPagamentoValorInputErrorMessage"
                ></v-text-field>
                <!-- <div class="d-flex justify-space-between ml-4">
                  Restante
                  <span v-if="moeda_conta === 'G$'">
                    {{ moeda_conta + "  " }}{{ valorRestante | guarani }}
                  </span>
                  <span v-else>
                    {{ moeda_conta + "  " }} {{ valorRestante | currency }}
                  </span>
                </div> -->
              </div>
            </v-col>
          </template>
        </template>
      </v-row>
      <div
        class="d-flex"
        v-if="selectedMoeda && formaPagamentoSelected.id !== 4"
      >
        <v-spacer></v-spacer>
        <div>
          <div>
            {{ $tc("global.restante") + " " + selectedMoeda.sigla }}
            <span v-if="selectedMoeda.sigla === 'G$'">
              {{ totalRestanteConvertido | guarani }}
            </span>
            <span v-else>
              {{ totalRestanteConvertido | currency }}
            </span>
          </div>

          <!-- <div
            v-if="totalTrocoConvertido > 0 && formaPagamentoSelected.id === 1"
          >
            {{ $tc("global.troco") + " " + selectedMoeda.sigla
            }}<span v-if="selectedMoeda.sigla === 'G$'">
              {{ totalTrocoConvertido | guarani }}
            </span>
            <span v-else>
              {{ totalTrocoConvertido | currency }}
            </span>
          </div> -->
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="formaPagamentoSelected && formaPagamentoSelected.id !== 8"
        depressed
        small
        color="button_2"
        class="white--text"
        @click="pushPagamento"
        :disabled="disableAddPagamento"
      >
        {{ $t("global.add") }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { fetchFormasPagamentos } from "@/api/formas-pagamento/formas_pagamento.js";
import { fetchCartoesBandeiras } from "@/api/cartoes-bandeiras/cartoes_bandeiras.js";
import { fetchCaixas } from "@/api/caixas/caixas.js";
import { mapState } from "vuex";
import { inputRequired } from "@/helpers/utils.js";

export default {
  name: "BaixarVendasAddFormaPago",

  components: {
    BaixaCheque: () => import("./BaixaCheque.vue"),
    BaixaAntecipo: () => import("./BaixaAntecipo.vue"),
  },

  props: {
    valorRestante: {
      type: Number,
    },
    baixaFatura: {
      type: Boolean,
      default: false,
    },
    moeda_conta: {
      type: String,
    },
    cliente_id: {
      type: Number,
    },
    numeros_cheque : {
      type: Array,
    }
  },

  data() {
    return {
      loadingFormaPagamentos: false,
      formaPagamentos: [],
      formaPagamentoSelected: null,
      formaPagamentoItem: {
        forma_pagamento: null,
        moeda: null,
        caixa_id: null,
        valor: null,
        documento: null,
        cheque: null,
        bandeira_id: null,
        fatura: null,
        consolidado: null,
        codigo_autorizacao: null,
      },
      formaPagamentoValor: null,
      caixas: [],
      caixaSelected: null,
      formaPagamentoValorInputError: false,
      formaPagamentoValorInputErrorMessage: null,
      cheques_criados: null,
      selectedMoeda: null,
      formaPagamentoCambio: null,
      pagamentoRestante: 0,
      formaPagamentoConsolidado: null,
      cambio_consolidado: 1,
      bandeiras: [],
      selectedBandeira: null,
      codigoAutorizacao: null,
      blockSelectedMoeda: false,
      allowCheque: false,
      chequeAdd: {},
      validForm: true,
      formRules: [inputRequired],
      antecipo: null,
      antecipos_adicionados: [],
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
    ...mapState("Cambio", {
      cambio: (state) => state.cambio,
    }),

    disableAddPagamento() {
      let result = false;

      if (
        this.formaPagamentoSelected?.id === 1 ||
        this.formaPagamentoSelected?.id === 2 ||
        this.formaPagamentoSelected?.id === 3 ||
        this.formaPagamentoSelected?.id === 6 ||
        this.formaPagamentoSelected?.id === 7 ||
        !this.formaPagamentoSelected?.id
      ) {
        let forma_pagamentos = ["forma_pagamento", "moeda", "valor"];
        forma_pagamentos.forEach((k) => {
          if (
            !this.formaPagamentoItem[k] ||
            this.allowBaixaCartao ||
            !this.selectedMoeda
          ) {
            result = true;
          }
        });
      }

      if (this.formaPagamentoSelected?.id === 2 && !this.caixaSelected) {
        result = true;
      }

      if (this.formaPagamentoSelected?.id === 4 && !this.allowCheque) {
        result = true;
      }

      if (this.formaPagamentoValorInputError) {
        result = true;
      }

      return result;
    },

    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },

    vCurrencyOptions2() {
      return {
        precision: this.moeda_conta === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },

    disableValor() {
      let result = false;

      if (!this.selectedMoeda) {
        result = true;
      }

      return result;
    },

    disableCambio() {
      let result = false;

      if (!this.selectedMoeda) {
        result = true;
      }
      if (this.moeda_conta === this.selectedMoeda?.sigla) {
        result = true;
      }

      return result;
    },
    allowBaixaCartao() {
      let result = false;
      if (
        this.formaPagamentoSelected?.id === 6 ||
        this.formaPagamentoSelected?.id === 7
      ) {
        if (!this.selectedBandeira || !this.codigoAutorizacao) {
          result = true;
        }
      }
      return result;
    },
    totalRestanteConvertido() {
      let cambio = this.$ci.parse(
        this.formaPagamentoCambio,
        this.vCurrencyOptions
      );
      let result = this.cambioResolve(
        this.moeda_conta,
        this.valorRestante,
        this.selectedMoeda.sigla,
        cambio
      );

      return result;
    },
    // totalTrocoConvertido() {
    //   let valor_pagamento_valor = this.$ci.parse(
    //     this.formaPagamentoValor ? this.formaPagamentoValor : 0,
    //     this.vCurrencyOptions
    //   );
    //   let valor_restante_convertido = this.totalRestanteConvertido;

    //   let valor_troco = valor_pagamento_valor - valor_restante_convertido;

    //   return valor_troco;
    // },
  },

  watch: {
    caixaSelected() {
      if (this.caixaSelected) {
        this.formaPagamentoItem.caixa_id = this.caixaSelected.id;
        if (this.formaPagamentoSelected.id === 2) {
          this.selectedMoeda = this.moedas.find((moeda) => {
            return moeda.sigla === this.caixaSelected.sigla;
          });
        }
      }
    },

    codigoAutorizacao() {
      if (this.codigoAutorizacao) {
        this.formaPagamentoItem.codigo_autorizacao = this.codigoAutorizacao;
      }
    },

    selectedBandeira() {
      if (this.selectedBandeira) {
        this.formaPagamentoItem.bandeira_id = this.selectedBandeira.id;
      }
    },
    formaPagamentoSelected() {
      if (this.formaPagamentoSelected) {
        this.caixas = [];
        this.caixaSelected = null;
        this.blockSelectedMoeda = false;
        this.formaPagamentoItem.forma_pagamento =
          this.formaPagamentoSelected.descricao;
        this.formaPagamentoItem.forma_de_pagamento_id =
          this.formaPagamentoSelected.id;
        this.selectedMoeda = null;
        this.cambio_consolidado = 0;

        if (this.formaPagamentoSelected.id === 2) {
          this.getCaixas();
        }
        if (
          this.formaPagamentoSelected.id === 6 ||
          this.formaPagamentoSelected.id === 7
        ) {
          this.getBandeiras();
          this.moedas.map((moeda) => {
            if (moeda.sigla === "R$" || moeda.sigla === "U$") {
              moeda.disabled = true;
            } else {
              moeda.disabled = false;
            }
          });
        } else {
          this.formaPagamentoItem.bandeira_id = null;
          this.formaPagamentoItem.codigo_autorizacao = null;
          this.selectedBandeira = null;
          this.codigoAutorizacao = null;
          this.moedas.map((moeda) => {
            moeda.disabled = false;
          });
        }
      } else {
        this.resetPagamento();
      }
    },

    formaPagamentoValor() {
      if (this.formaPagamentoValor) {
        this.formaPagamentoItem.valor = this.$ci.parse(
          this.formaPagamentoValor,
          this.vCurrencyOptions
        );
        // - this.totalTrocoConvertido;

        this.atualizarConsolidado();
      }
    },

    async selectedMoeda() {
      if (this.selectedMoeda) {
        this.blockSelectedMoeda = true;
        if (this.formaPagamentoSelected.id !== 8) {
          this.formaPagamentoItem.moeda = this.selectedMoeda.sigla;
          this.$ci.setValue(this.$refs.formaPagamentoValor, 0);
          await this.setCambio();
        }
      }
    },
    formaPagamentoConsolidado() {
      if (this.formaPagamentoConsolidado) {
        this.validatePagamentoValor();
      }
    },
  },

  methods: {
    cambioResolve(moeda_origem, valor_origem, moeda_destino, valor_destino) {
      let result = null;
      valor_origem = Number(valor_origem);
      valor_destino = Number(valor_destino);

      // GUARANI
      if (moeda_origem === "G$" && moeda_destino === "U$") {
        result = valor_origem / valor_destino;
      }
      if (moeda_origem === "G$" && moeda_destino === "R$") {
        result = valor_origem / valor_destino;
      }

      // REAL
      if (moeda_origem === "R$" && moeda_destino === "U$") {
        result = valor_origem / valor_destino;
      }
      if (moeda_origem === "R$" && moeda_destino === "G$") {
        result = valor_destino * valor_origem;
      }

      // DOLAR
      if (moeda_origem === "U$" && moeda_destino === "R$") {
        result = valor_destino * valor_origem;
      }
      if (moeda_origem === "U$" && moeda_destino === "G$") {
        result = valor_destino * valor_origem;
      }

      if (
        (moeda_origem === "U$" && moeda_destino === "U$") ||
        (moeda_origem === "R$" && moeda_destino === "R$") ||
        (moeda_origem === "G$" && moeda_destino === "G$")
      ) {
        result = valor_origem * valor_destino;
      }

      return result ? result + Number.EPSILON : result;
    },

    consolidadoToCambio(
      moeda_origem,
      valor_origem,
      moeda_destino,
      valor_destino
    ) {
      let result = null;
      valor_origem = Number(valor_origem);
      valor_destino = Number(valor_destino);

      // GUARANI
      if (moeda_origem === "G$" && moeda_destino === "U$") {
        result = valor_origem / valor_destino;
      }
      if (moeda_origem === "G$" && moeda_destino === "R$") {
        result = valor_origem / valor_destino;
      }

      // REAL
      if (moeda_origem === "R$" && moeda_destino === "U$") {
        result = valor_origem / valor_destino;
      }
      if (moeda_origem === "R$" && moeda_destino === "G$") {
        result = valor_destino / valor_origem;
      }

      // DOLAR
      if (moeda_origem === "U$" && moeda_destino === "R$") {
        result = valor_destino / valor_origem;
      }
      if (moeda_origem === "U$" && moeda_destino === "G$") {
        result = valor_destino / valor_origem;
      }

      if (
        (moeda_origem === "U$" && moeda_destino === "U$") ||
        (moeda_origem === "R$" && moeda_destino === "R$") ||
        (moeda_origem === "G$" && moeda_destino === "G$")
      ) {
        result = valor_origem / valor_destino;
      }

      return result ? result + Number.EPSILON : result;
    },

    changeConsolidado() {
      if (Number(this.formaPagamentoConsolidado) > 0) {
        this.formaPagamentoItem.consolidado = this.$ci.parse(
          this.formaPagamentoConsolidado,
          this.vCurrencyOptions2
        );

        let cambio = this.consolidadoToCambio(
          this.selectedMoeda.sigla,
          this.formaPagamentoItem.valor,
          this.moeda_conta,
          this.$ci.parse(this.formaPagamentoConsolidado, this.vCurrencyOptions)
        );
        this.$ci.setValue(this.$refs.formaPagamentoCambio, cambio);
      }
    },

    setCambio() {
      this.cambio_consolidado = 0;
      if (
        (this.selectedMoeda.sigla === "G$" && this.moeda_conta === "G$") ||
        (this.selectedMoeda.sigla === "R$" && this.moeda_conta === "R$") ||
        (this.selectedMoeda.sigla === "U$" && this.moeda_conta === "U$")
      ) {
        this.cambio_consolidado = 1;
      }

      if (
        (this.selectedMoeda.sigla === "G$" && this.moeda_conta === "U$") ||
        (this.selectedMoeda.sigla === "U$" && this.moeda_conta === "G$")
      ) {
        if (this.formaPagamentoSelected?.id === 6) {
          this.cambio_consolidado = this.cambio.guarani_credito;
        } else if (this.formaPagamentoSelected?.id === 7) {
          this.cambio_consolidado = this.cambio.guarani_debito;
        } else {
          this.cambio_consolidado = this.cambio.guarani_venda;
        }
      }

      if (
        (this.selectedMoeda.sigla === "G$" && this.moeda_conta === "R$") ||
        (this.selectedMoeda.sigla === "R$" && this.moeda_conta === "G$")
      ) {
        this.cambio_consolidado = this.cambio.guarani_real;
      }

      if (
        (this.selectedMoeda.sigla === "R$" && this.moeda_conta === "U$") ||
        (this.selectedMoeda.sigla === "U$" && this.moeda_conta === "R$")
      ) {
        this.cambio_consolidado = this.cambio.real_venda;
      }
      if (this.formaPagamentoSelected.id !== 8) {
        this.$ci.setValue(
          this.$refs.formaPagamentoCambio,
          this.cambio_consolidado
        );
      }
    },

    atualizarCambio() {
      let consolidado = this.$ci.parse(
        this.formaPagamentoConsolidado,
        this.vCurrencyOptions
      );
      if (consolidado > 0) {
        this.changeConsolidado();
      }
      this.validatePagamentoValor();
    },

    atualizarConsolidado() {
      let cambio = this.$ci.parse(
        this.formaPagamentoCambio,
        this.vCurrencyOptions
      );

      if (cambio > 0) {
        let changeConsolidado = this.cambioResolve(
          this.selectedMoeda.sigla,
          this.formaPagamentoItem.valor,
          this.moeda_conta,
          cambio
        );

        this.$ci.setValue(
          this.$refs.formaPagamentoConsolidado,
          changeConsolidado
        );

        this.formaPagamentoItem.consolidado = changeConsolidado;

        this.validatePagamentoValor();
      }
    },

    getBandeiras() {
      this.loading = true;
      this.bandeiras = [];
      fetchCartoesBandeiras(
        `?tipo=${this.formaPagamentoSelected.id === 6 ? "CREDITO" : "DEBITO"}`
      )
        .then((response) => {
          this.bandeiras = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    getFormasDePagamento() {
      this.loadingFormaPagamentos = true;
      fetchFormasPagamentos()
        .then((response) => {
          this.formaPagamentos = response.map((item) => {
            if (
              item.id != 1 &&
              item.id != 2 &&
              item.id != 4 &&
              item.id != 6 &&
              item.id != 7 &&
              item.id != 8
            ) {
              item.disabled = true;
            } else {
              item.disabled = false;
            }

            return item;
          });
        })
        .finally(() => (this.loadingFormaPagamentos = false));
    },

    resetPagamento() {
      var self = this;

      Object.keys(this.formaPagamentoItem).forEach(function (key, index) {
        if (key != "moeda") {
          self.formaPagamentoItem[key] = null;
        }
      });

      this.formaPagamentoValor = null;
      this.formaPagamentoCambio = null;
      this.selectedBandeira = null;
      this.codigoAutorizacao = null;
      this.caixaSelected = null;
      this.selectedMoeda = null;
    },

    validatePagamentoValor() {
      if (!this.formaPagamentoItem.valor) return;
      else {
        this.formaPagamentoValorInputError = false;
        this.formaPagamentoValorInputErrorMessage = "";
      }
    },

    pushCheque() {
      this.formaPagamentoItem.valor = this.chequeAdd.valor;
      this.formaPagamentoItem.consolidado = this.chequeAdd.consolidado;
      this.formaPagamentoItem.moeda = this.chequeAdd.moeda_sigla;
      this.formaPagamentoItem.cambio = this.chequeAdd.cambio;
      this.formaPagamentoItem.cheque = this.chequeAdd;

      this.chequeAdd = {};
      this.allowCheque = false;
    },

    pushPagamento() {
      if (this.formaPagamentoSelected.id === 4) {
        this.pushCheque();
      } else {
        this.formaPagamentoItem.cambio = this.$ci.parse(
          this.formaPagamentoCambio,
          this.moeda_conta == "G$"
            ? this.vCurrencyOptions2
            : this.vCurrencyOptions
        );
      }
      let forma_pagamento = { ...this.formaPagamentoItem };

      this.$emit("push-pagamento", forma_pagamento);
      this.resetPagamento();
      this.formaPagamentoSelected = null;
    },

    enableSelectMoeda() {
      this.blockSelectedMoeda = false;
      this.selectedMoeda = null;
      if (this.formaPagamentoSelected.id === 2) {
        this.caixaSelected = null;
        this.formaPagamentoItem.caixa_id = null;
      }
    },

    getCaixas() {
      this.loading = true;
      fetchCaixas("?tipo_caixa=CONTA CORRENTE")
        .then((response) => {
          this.caixas = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    pushAntecipo() {
      if (this.antecipo) {
        this.selectedMoeda = this.moedas.find((moeda) => {
          return moeda.sigla === this.antecipo.moeda;
        });
        this.setCambio();

        let consolidado_antecipo = this.cambioResolve(
          this.selectedMoeda.sigla,
          this.antecipo.saldo,
          this.moeda_conta,
          Number(this.cambio_consolidado)
        );
        if (Number(consolidado_antecipo) > this.valorRestante) {
          let valor_antecipo_utilizaco = this.cambioResolve(
            this.moeda_conta,
            this.valorRestante,
            this.selectedMoeda.sigla,
            Number(this.cambio_consolidado)
          );
          this.formaPagamentoItem.valor = valor_antecipo_utilizaco;
          this.formaPagamentoItem.consolidado = this.valorRestante;
        } else {
          this.formaPagamentoItem.valor = this.antecipo.saldo;
          this.formaPagamentoItem.consolidado = consolidado_antecipo;
        }
        this.formaPagamentoItem.moeda = this.selectedMoeda.sigla;
        this.formaPagamentoItem.cambio = this.cambio_consolidado;
        this.formaPagamentoItem.antecipo_id = this.antecipo.id;
        this.antecipos_adicionados.push(this.antecipo.id);
        let forma_pagamento = { ...this.formaPagamentoItem };
        this.$emit("push-pagamento", forma_pagamento);
        this.resetPagamento();
        this.formaPagamentoSelected = null;
        this.antecipo = null;
      }
    },
  },

  mounted() {
    this.cheques_criados = this.numeros_cheque;
    this.getFormasDePagamento();
  },
};
</script>

<style></style>
